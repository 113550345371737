import React from 'react';
import FederacaoList from '../../components/FederacaoList';
import { Title } from '../../components/UI';

function Clubes() {
  return (
  <>
  <Title>Relação de Clubes</Title>
  <FederacaoList />
  </>);
}

export default Clubes;
