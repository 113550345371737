import styled from 'styled-components';
export const TextTitle = styled.p`
  color: #333;
  font-size: 24px;
  text-align: center;
  margin: 10px 0 0 0;
`;
export const TextSmall = styled.p`
  color: #333;
  font-size: 12px;
  text-align: center;
  margin: 0 0 10px 0;
`;
export const Text = styled.p`
  color: #333;
  font-size: 16px;
  text-align: left;
  margin: 10px 0;
`;
export const TextCentered = styled.p`
  color: #333;
  font-size: 16px;
  text-align: center;
  margin: 10px 0;
`;

export const Title = styled.h2`
  color: #000;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px 0;
`;

export const Subtitle = styled.p`
  color: #04704c;
  font-size: 14px;
`;

export const List = styled.ul`
  padding: 20px;
`;
export const ListItem = styled.li`
  font-size: 14px;
`;

export const Spacer = styled.div`
  ${props => props.sm ? 'width:10px;height:10px;' : props.md ? 'width:20px;height:10px;' : 'width:30px;height:10px;'}
`;

export const PageHeader = styled.div`
  height: 225px;
  /* background: red; */
  background-image: url('${props => props.image}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media(min-width: 600px) {
    background: none;
  }
`;

export const PageCard = styled.div`
  background: #fff;
  border-radius: 20px 20px 0 0; 
  padding: 20px;
  max-width: 600px;
  margin: -65px auto 0 auto;
  box-shadow: #0006 0 0 16px;
`;

export const Image = styled.img`
  max-width: 100%;
`;