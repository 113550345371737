import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    box-sizing: border-box;
  }
  body{
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  body,input,button{
    
    font-size: 16px;
  }
  .App{
    max-width: 640px;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 20px;
    padding-right: 20px;
  } 
`;
