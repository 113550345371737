import styled from 'styled-components';

export const Title = styled.h2`
    font-size: 18px;
    color: #333;
`;

export const Buracos = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
    
    svg {
        font-size: 24px;
        margin: 0 10px 0 0;
        color: #333;
    }
`;

export const Numero = styled.span`
    background: #04704c;
    width: 30px;
    height: 30px;
    min-width: 30px;
    max-height: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
`;

export const Campos = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Select = styled.select`
    width: 100%;
    border: 1px solid #ccc;
`;

export const Nome = styled.span`
    color: #999;
    font-size: 18px;
`;