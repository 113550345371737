import React from 'react';
import LogoGolfCia from '../../assets/logo.png';
import LogoPortalGolfe from '../../assets/portal-do-golfe.png';
import LogoFeb from '../../assets/logo-feb.png';
import FederacaoData from '../../data/federacoes.json';

import {
  Title,
  TextTitle,
  TextSmall,
  TextCentered
} from '../../components/UI';


import { Images, Image } from './styles';

function Home() {
  return (
    <div>
      <Title>Sobre o Concurso</Title>
      <TextCentered>
        A <b>Golfe & Cia</b> e o <b>Portal Brasileiro do Golfe</b>, com o apoio
        da <b>Confederação Brasileira de Golfe</b> e as federações, estão realizando um concurso
        virtual para eleger
      </TextCentered>
      <TextTitle>
        <b>"Os 18+ BR"</b>
      </TextTitle>
      <TextSmall>
        Os 18 buracos mais bonitos do Brasil.
      </TextSmall>
      <TextCentered>
        COM ÓTIMA PREMIAÇÃO!!!
      </TextCentered>
      <TextCentered>
        VOTE EM QUANTOS BURACOS VOCÊ QUISER!
      </TextCentered>
      <h4>Apoio:</h4>
      <Images>
      {FederacaoData.map((federacao) => {
          return <img src={`/assets/logo-federacao-${federacao.id}.png`} key={federacao.id} alt={federacao.name}></img>;
        })} 
      </Images>
      <h4>Realização:</h4>
      <Images>
        <a href="http://golfecia.com.br/" target="_blank" rel="noopener noreferrer" ><img src={LogoGolfCia} alt='Golf e Cia'></img></a>
        <a href="http://golf.esp.br/" target="_blank" rel="noopener noreferrer" ><img src={LogoPortalGolfe} alt='Portal do Golfe'></img></a>
      </Images>
      <h4>Produzido por:</h4>
      <a href="https://www.febinfo.com.br/" target="_blank" rel="noopener noreferrer" ><Image src={LogoFeb} alt='FEB Informática Comércio e Serviços Ltda.' /></a>
      
    </div>
  );
}

export default Home;
