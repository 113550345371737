import styled from 'styled-components';

export const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  justify-content: space-around;
  align-items: center;
  
  img {
    margin: 10px;
    max-width: 25vw;
    max-width: 100px;
    height: auto;
  }
`;

export const Image = styled.img`
  margin: 10px 0;
  width: 25vw;
  max-width: 100px;
  height: auto;
`;
