import React from 'react';
import { Nav } from './styles';
import { NavLink } from 'react-router-dom';
import { FiHome, FiFileText } from 'react-icons/fi';
import { FaVoteYea } from 'react-icons/fa'; 

// import { Container } from './styles';

const Footer: React.FC = () => {
  return (
    <Nav>
    <NavLink exact activeClassName='active' to='/'>
      <FiHome />
      Os 18+ BR
    </NavLink>
    <NavLink exact activeClassName='active' to='/regulamento'>
      <FiFileText />
      Prêmios
    </NavLink>
    <NavLink exact activeClassName='active' to='/cadastro'>
      <FaVoteYea />
      Votar
    </NavLink>
    <NavLink exact activeClassName='active' to='/clubes'>
      <FiFileText />
      Clubes
    </NavLink>
  </Nav>
  );
}

export default Footer;