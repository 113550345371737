import React from 'react';

import { Title, Text } from '../../components/UI'; 
import { Link } from 'react-router-dom';
import { Images } from './styles';

function Regulamento() { 
  return (
    <div>
      <Title><b>Prêmios (item 5):</b></Title>
      <Text>
      <b>O participante que acertar o maior número de buracos entre os mais votados (do 1 ao 18) por todos ganhará green-fees para jogar nos 22 campos que apoiaram o concurso (veja lista abaixo).       Em caso de empate na primeira   colocação, esses 22 green-fees serão distribuídos por sorteio entre os que dividirem o primeiro lugar. Outros 22 green-fees, um de cada clube, serão sorteados entre os demais participantes.</b>
      </Text>
      <Text>
      E os <b>três primeiros</b> colocados do concurso <b>ganharão</b> ainda, cada um, <b>convites</b> para jogar em três etapas do  TERRAS FRIENDS – With Awards, no Terras de São José Golfe Clube.
      </Text>
        <Title>Regulamento</Title>
        <Text><b>1. Concurso:</b> Vote nos 18 buracos mais bonitos (do 1 ao 18) dos campos de golfe do Estado de São Paulo. Poderão ser votados apenas buracos de clubes filiados à Federação Paulista de Golfe (FPG) localizados no estado de São Paulo.</Text>
        <Text><b>2. Participantes:</b> Poderão participar todos os golfistas federados (é necessário ter handicap índex oficial no Brasil) e todos os profissionais de golfe filiados à Confederação Brasileira de Golfe (CBG) ou à Associação Brasileira e Profissionais de Golfe (PGA do Brasil).</Text>
        <Text><b>3. Votação:</b> A votação se dará através do preenchimento do <Link to="/cadastro">formulário de votação</Link>. Para votar é obrigatório preencher previamente o cadastro do golfista.</Text>
        <Text><b>Prazo:</b> Serão computados todos os votos recebidos até às 23h59 do dia 15 de julho 2020.</Text>
        <Text><b>4. Vencedor:</b> Será considerado vencedor o golfista que tiver votado no maior número de buracos (do 1 ao 18) escolhidos pela maioria dos participantes do concurso.</Text>
        <Text><b>5. Premiação</b></Text>
        <Text><b>5.1 O TERRAS FRIENDS WHIT AWARDS dará aos três primeiros colocados do concurso "Os 18 + SP " o direito de participar gratuitamente de 3 (três) etapas do circuito TERRAS FRIENDS WHIT AWARDS. Em caso de empate será feito um sorteio para determinar quem serão os três golfistas que receberão os convites gratuitos para o TERRAS FRIENDS WHIT AWARDS.</b></Text>
        <Text><b>5.2 O primeiro colocado do concurso "Os 18 + SP " receberá também um green fee para jogar em cada um dos clubes abaixo relacionados. Se houver empate na primeira colocação do concurso, os green fees serão sorteados entre eles. Após o sorteio, caso haja sobra de greens fees (número não exato para a divisão), os green fees restantes serão sorteados juntamente com os do item 5.3.</b></Text>
        <Text><b>5.3 Será sorteado ainda entre todos os participantes do concurso "Os 18 + SP " não contemplados no item 5.2 um green fee de cada um dos clubes abaixo relacionados.</b></Text>
        <h4>Hotéis:</h4>
        <Images>
        <img src={`/assets/logo-hotel-dom-pedro-laguna.png`} alt='Dom Pedro Laguna'></img>
        <img src={`/assets/logo-hotel-eko-residence.png`} alt='Eko Residence'></img>
        <img src={`/assets/logo-hotel-fradissimo.png`} alt='Fradíssimo'></img>
        <img src={`/assets/logo-hotel-mata-nativa.png`} alt='Mata Nativa'></img>
        <img src={`/assets/logo-hotel-nobile.png`} alt='Nóbile'></img>
        <img src={`/assets/logo-hotel-novotel.png`} alt='Novotel'></img>
        <img src={`/assets/logo-hotel-santinho.png`} alt='Santinho'></img>
        <img src={`/assets/logo-hotel-villa-rossa.png`} alt='Villa Rossa'></img>
        <img src={`/assets/logo-hotel-vogue.png`} alt='Vogue'></img>
        <img src={`/assets/logo-hotel-wish.png`} alt='Wish'></img>
        </Images>
    </div>
  );
}

export default Regulamento;
