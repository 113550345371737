import styled from 'styled-components';

export const Form = styled.form`
  margin-top: 10px;
  .submit {
    background: #04704c;
    &:hover {
      background: #27ae60;
    }
  }
`;

export const Container = styled.div`
  padding: 15px;
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  .nextBtn {
    background: #04704c !important;
  }
  .prevBtn:not(:disabled) {
    color: #04704c;
  }
`;
export const Images = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  img {
    margin: 10px 0;
    width: 25vw;
    max-width: 100px;
    height: auto;
  }
`;

export const Image = styled.img`
  margin: 10px 0;
  width: 25vw;
  max-width: 100px;
  height: auto;
`;
export const LogoWrapper = styled.div`
  text-align: center;
  h2{
      font-weight:bold;
      color: #04704c;
      margin-bottom:15px;
  }
`;
