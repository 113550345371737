import styled from 'styled-components'; 
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const List = styled.ul`
  margin-top:15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  list-style: none;
`;
export const Item = styled.li`
  display: flex;
  flex-direction: column;
  background: #e6e6e6;
  border-radius: 4px;
  padding: 15px;
  font-size: 16px;
`;
export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Name = styled.strong``;
export const Holes = styled.span`
font-size:14px;
display:none;
`;
