import React from 'react';
import LogoConcurso from '../../assets/logo-18-mais-br-3.png';
import LogoGolfCia from '../../assets/logo.png';
import LogoPortalGolfe from '../../assets/portal-do-golfe.png';
import LogoFeb from '../../assets/logo-feb.png';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Title, Text } from '../../components/UI';
import MaskedInput from 'react-text-mask';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Cadastrado from '../Cadastrado';
import { toast } from 'react-toastify';
import api from '../../services/api';
import * as Yup from 'yup';

import { Form, Buttons, Images, Image, LogoWrapper } from './styles';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stepper,
  Step,
  StepLabel,
  Collapse,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);
interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;
  

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
interface State {
  step: number;
  name: string;
  email: string;
  phone: string;
  club: string;
  category: string;
  hcp?: number;
  hcpIndex?: string;
  votos: any;
}
interface Error {
  name: string;
  email: string;
  phone: string;
  club: string;
  category: string;
  hcp: string;
  hcpIndex: string;
}
function getSteps() {
  return ['Cadastro', 'Votação', 'Conclusão'];
}
function Cadastro() {
  const classes = useStyles();
  const steps = getSteps();
  const [submitting,setSubmitting] = React.useState<boolean>(false);
  const [cadErrors, setErrors] = React.useState<Error>({
    name: '',
    email: '',
    phone: '',
    club: '',
    category: '',
    hcp: '',
    hcpIndex: '',
  });
  const [values, setValues] = React.useState<State>({
    step: 1,
    name: '',
    email: '',
    phone: '',
    club: '',
    category: '',
    hcp: undefined,
    hcpIndex: '',
    votos: [],
  });
  const cadSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    phone: Yup.string().required(),
    club: Yup.string().required(),
    category: Yup.string().required(),
    hcp: Yup.number().when('category', {
      is: 'amador',
      then: Yup.number()
        .required('Código HCP obrigatório.')
        .test(
          'len',
          'Deve ter exatamente 9 dígitos',
          (val) => ('' + val).length === 9
        ),
    }),
    hcpIndex: Yup.string().when('category', {
      is: 'amador',
      then: Yup.string().required('HCP Index obrigatório.'),
    }),
  });
  
  const validaCadastro = async () => {
    // await cadSchema.isValid(values).then(async (isValid) => {
    //   if (!isValid) {
    //     await cadSchema.validate(values).catch((errors) => {
    //       console.log(errors);
    //     });
    //     return isValid;
    //   }
    // });
    // return false;
    await cadSchema.validate(values).catch(async (errors) => {
      console.log(errors);
            const error = errors.errors[0];
            const def = {
              name: '',
              email: '',
              phone: '',
              club: '',
              category: '',
              hcp: '',
              hcpIndex: '',
            };
             setErrors({...def,[`${errors.path}`]:error});
           });
    return await cadSchema.isValid(values);
    // return (
    //   values.name &&
    //   values.email &&
    //   values.phone &&
    //   values.club &&
    //   (values.category === 'profissional' || (values.hcp && values.hcpIndex))
    // );
  };
  const validaBuracos = () => {
    const count = values.votos.filter((e: any) => e.clube_id);
    return count.length > 0;
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeBuracos = (votos: any) => {
    setValues({
      ...values,
      votos,
    });
  };
  const handleChangeCategory = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setValues({
      ...values,
      category: event.target.value as string,
    });
  };

  const nextStep = async() => {
    const { step } = values;
    if (step === 1 && ! await validaCadastro()) {
      toast.error(`Preencha todos os campos corretamente`   );
    } else if (step === 2 && !validaBuracos()) {
      toast.error('Selecione pelo menos um clube');
    } else if (step === 2) {
      setSubmitting(true);
      const data  = {...values,votos: values.votos.filter((e:any)=>e.clube_id !== '').map((x:any) => {return {buraco:x.buraco,clube_id:x.clube_id}})};
      api
        .post('/api/votacao', data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(
          (data) => {
            setSubmitting(false);
            console.log(data);
            setValues({
              ...values,
              step: step + 1,
            });
            window.scrollTo(0, 0);
          },
          (err) => {
            setSubmitting(false);
            console.log(err);
            toast.error('Já foi realizada uma votação para este e-mail.');
          }
        )
        .catch((error) => {
          throw error;
        });
    } else {
      setValues({
        ...values,
        step: step + 1,
      });
      window.scrollTo(0, 0);
    }
  };
  const prevStep = () => {
    const { step } = values;
    setValues({
      ...values,
      step: step - 1,
    });
    window.scrollTo(0, 0);
  };
  const getStepContent = (step: number) => {
    switch (step) {
      case 1:
        return (
          <Form noValidate autoComplete='off'>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                value={values.name}
                onChange={handleChange}
                name='name'
                label='Nome'
                variant='outlined'
                error={cadErrors.name.length > 0}
                helperText={cadErrors.name}
                required
              />
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                value={values.email}
                onChange={handleChange}
                name='email'
                type='email'
                label='E-mail particular'
                variant='outlined'
                error={cadErrors.email.length > 0}
                helperText={cadErrors.email}

                required
              />
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                name='phone'
                id='phone'
                type='tel'
                variant='outlined'
                label='Celular'
                error={cadErrors.phone.length > 0}
                helperText={cadErrors.phone}

                InputProps={{
                  inputComponent: TextMaskCustom as any,
                  value: values.phone,
                  onChange: handleChange,
                }}
              />
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                name='club'
                id='club'
                value={values.club}
                onChange={handleChange}
                label='Clube'
                variant='outlined'
                error={cadErrors.club.length > 0}
                helperText={cadErrors.club}

                required
              />
            </FormControl>
            <FormControl
              fullWidth
              className={classes.formControl}
              variant='outlined'
            >
              <InputLabel id='lblCategoria'>Categoria</InputLabel>
              <Select
                labelId='lblCategoria'
                value={values.category}
                onChange={handleChangeCategory}
                labelWidth={85}
                required
                error={cadErrors.category.length > 0}
                
              >
                <MenuItem value='profissional'>Profissional</MenuItem>
                <MenuItem value='amador'>Amador</MenuItem>
              </Select>
            </FormControl>
            <Collapse in={values.category === 'amador'}>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name='hcp'
                  id='hcp'
                  value={values.hcp}
                  onChange={handleChange}
                  type='number'
                  fullWidth
                  label='Cod HCP'
                  variant='outlined'
                  error={cadErrors.hcp.length > 0}
                  helperText={cadErrors.hcp}
  
                  required
                />
              </FormControl>
              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  name='hcpIndex'
                  id='hcpIndex'
                  value={values.hcpIndex}
                  onChange={handleChange}
                  type='text'
                  fullWidth
                  label='HCP Index'
                  variant='outlined'
                  error={cadErrors.hcpIndex.length > 0}
                  helperText={cadErrors.hcpIndex}
  
                />
              </FormControl>
            </Collapse>
          </Form>
        );
      case 2:
        return <Cadastrado onChange={handleChangeBuracos} />;
      default:
        break;
    }
    return null;
  };

  return (
    <div id='Cadastro'>
      <Title>Votação</Title>
      <Stepper activeStep={values.step - 1} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {values.step === steps.length ? (
        <>
          <LogoWrapper>
            <Title>Obrigado!</Title>
            <img src={LogoConcurso} alt='Os 18+ BR' />
          </LogoWrapper>
          <Text>
            Sua votação foi concluída. Agora é só aguardar os resultados.
          </Text>
          <Text>
            Acesso nossa{' '}
            <a
              href='https://www.facebook.com/events/699687900766158/?active_tab=discussion'
              target='_blank'
              rel='noopener noreferrer'
            >
              página do concurso no Facebook
            </a>{' '}
            para conhecer as fotos dos buracos e incluir as fotos dos buracos
            que você mais gosta.
          </Text>
          <Text>
            Clique nos logotipos das empresas abaixo para acessar os respectivos
            sites.
          </Text>
          <h4>Realização:</h4>
          <Images>
            <a
              href='http://golfecia.com.br/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={LogoGolfCia} alt='Golf e Cia'></img>
            </a>
            <a
              href='http://golf.esp.br/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={LogoPortalGolfe} alt='Portal do Golfe'></img>
            </a>
          </Images>
          <h4>Produzido por:</h4>
          <a
            href='https://www.febinfo.com.br/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Image
              src={LogoFeb}
              alt='FEB Informática Comércio e Serviços Ltda.'
            />
          </a>
        </>
      ) : (
        <>
          {getStepContent(values.step)}
          <Buttons>
            <Button
              disabled={values.step === 1}
              onClick={prevStep}
              size='large'
              disableElevation
              className='prevBtn'
            >
              Voltar
            </Button>
            <Button
              variant='contained'
              onClick={nextStep}
              size='large'
              color='primary'
              className='nextBtn'
              disableElevation
              disabled={submitting}
            >
              {values.step === steps.length - 1 ? 'Enviar' : 'Avançar'}
            </Button>
          </Buttons>
        </>
      )}
    </div>
  );
}

export default Cadastro;
