import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const List = styled.ul`
  margin-top:15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  list-style: none;
`;
export const Item = styled.li`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  font-size: 16px;
`;
export const ItemHeader = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  img{
    margin-bottom:10px;
  }
`;
export const Name = styled.strong`
text-align:center;
`;
export const Holes = styled.span`
font-size:14px;
`;
