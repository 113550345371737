import React from 'react';

import { Container,Spacer } from './styles';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo-18-mais-br-3.png'


const Header: React.FC = () => {
  return (
    <Container>
      <Link to="/">
        <img src={logo} alt="Os 18+ BR" />
      </Link>
      <strong>Os 18+ BR</strong>
      <Spacer></Spacer>
    </Container>
  );
}

export default Header;