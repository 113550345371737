import React from 'react';

import { Container,List,Item, ItemHeader, Name, Holes } from './styles';
import ClubeData from '../../data/clubes.json';
interface ChildProps {
  federacao: any
}
const ClubeList: React.FC<ChildProps> = (props) => {
  return (
    <Container>
      <List>
        {
        ClubeData.filter(e => e.federacao_id === props.federacao.id).map((clube) => {
          return <Item key={clube.id}> 
            
            <ItemHeader><Name>{clube.name}</Name><Holes>{clube.holes} buracos</Holes></ItemHeader>
            <span>{clube.title} - {clube.code}</span>
            </Item>;
        })}
      </List>
    </Container>
  );
};

export default ClubeList;
