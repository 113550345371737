import React, { useState, useEffect } from 'react';

import { Text } from '../../components/UI';

import { Buracos, Item, Numero} from './styles';

import { GiGolfFlag } from 'react-icons/gi';

import ClubeData from '../../data/clubes.json';
import FederacaoData from '../../data/federacoes.json';
import { FormControl, MenuItem, makeStyles, Select, createStyles, Theme, Box } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);
function Cadastrado(props:any) {
  const classes = useStyles();
  const [buracos, setBuracos] = useState([
    { buraco: 1, federacao_id:'', clube_id: '' },
    { buraco: 2, federacao_id:'', clube_id: '' },
    { buraco: 3, federacao_id:'', clube_id: '' },
    { buraco: 4, federacao_id:'', clube_id: '' },
    { buraco: 5, federacao_id:'', clube_id: '' },
    { buraco: 6, federacao_id:'', clube_id: '' },
    { buraco: 7, federacao_id:'', clube_id: '' },
    { buraco: 8, federacao_id:'', clube_id: '' },
    { buraco: 9, federacao_id:'', clube_id: '' },
    { buraco: 10, federacao_id:'', clube_id: '' },
    { buraco: 11, federacao_id:'', clube_id: '' },
    { buraco: 12, federacao_id:'', clube_id: '' },
    { buraco: 13, federacao_id:'', clube_id: '' },
    { buraco: 14, federacao_id:'', clube_id: '' },
    { buraco: 15, federacao_id:'', clube_id: '' },
    { buraco: 16, federacao_id:'', clube_id: '' },
    { buraco: 17, federacao_id:'', clube_id: '' },
    { buraco: 18, federacao_id:'', clube_id: '' },
  ]);
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleChange(idx: number, e: React.ChangeEvent<{value:unknown}>) {
    const aux = buracos.map((b,x)=>{
      if(x === idx){
        return {...b,clube_id:e.target.value as string}
      } 
      return b
    });
    props.onChange(aux);
    setBuracos(aux);
  }
  function handleChangeFederacao(idx: number, e: React.ChangeEvent<{value:unknown}>) {
    const aux = buracos.map((b,x)=>{
      if(x === idx){
        return {...b,federacao_id:e.target.value as string}
      } 
      return b
    });
    props.onChange(aux);
    setBuracos(aux);
  }

  return (
    <div>
      <Text>Selecione um clube para cada buraco.</Text>
      <Buracos>
        {buracos.map((buraco,idx) => (
          <Item key={buraco.buraco}>
            <GiGolfFlag></GiGolfFlag>
            <Numero>{buraco.buraco}</Numero>
            
            <Box width="75%">
            <FormControl
              fullWidth
              className={classes.formControl}
              
            >
              <Select
              value={buraco.federacao_id || ''}
                onChange={(e: React.ChangeEvent<{value:unknown}>) => {
                  handleChangeFederacao(idx, e);
                }}
                placeholder='Selecione a Federação'
                labelWidth={85}
                required
              >
                {FederacaoData.map((federacao) => (
                  <MenuItem key={federacao.id} value={federacao.id}>{federacao.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              className={classes.formControl}
              
            >
              <Select
              value={buraco.clube_id || ''}
                onChange={(e: React.ChangeEvent<{value:unknown}>) => {
                  handleChange(idx, e);
                }}
                placeholder='Selecione o Clube'
                labelWidth={85}
                required
              >
                {ClubeData.filter(c => c.federacao_id === parseInt(buraco.federacao_id) ).map((clube) => (
                  <MenuItem key={clube.id} value={clube.id}>{clube.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            </Box>
             
          </Item>
        ))}
      </Buracos>
     
    </div>
  );
}

export default Cadastrado;
