import React from 'react';

import { Container,List,Item, ItemHeader, Name } from './styles';
import FederacaoData from '../../data/federacoes.json';
import ClubeList from '../ClubeList';
const FederacaoList: React.FC = () => {
  return (
    <Container>
      <List>
        {FederacaoData.map((federacao) => {
          return <Item key={federacao.id}> 
            <ItemHeader><img src={`/assets/logo-federacao-${federacao.id}.png`} alt={federacao.name} /><Name>{federacao.name}</Name></ItemHeader>
            <ClubeList federacao={federacao}></ClubeList>
            </Item>;
        })} 
      </List>
    </Container>
  );
};

export default FederacaoList;
