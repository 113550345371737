import styled from 'styled-components';
export const Container = styled.header`
  background: #151827;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 5px;
  padding-top: calc(5px + constant(safe-area-inset-top));
  padding-top: calc(5px + env(safe-area-inset-top));
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  img {
    height: 60px;
    width: auto;
    display: block;
  }
`;
export const Spacer = styled.span`
  width: 60px;
  display: block;
`;
