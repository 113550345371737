import styled from 'styled-components';

export const Title = styled.h2`
    font-size: 18px;
    color: #333;
`;
export const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  justify-content: space-around;
  align-items: center;
  
  img {
    margin: 10px;
    max-width: 25vw;
    max-width: 100px;
    height: auto;
  }
`;