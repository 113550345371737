import styled from 'styled-components';

export const Nav = styled.nav`
  z-index: 1000;
  background: #fff;
  border-top: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left:0;
  right:0;
  bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  svg {
    font-size: 24px;
    margin: 0 0 3px 0;
  }
  small{
    text-align:center;
    align-self:center;
  }
  a {
    color: #999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    text-decoration: none;
    flex: 1;
    height: 60px;
    
    &::after {
      content: '';
      width: 90%;
      margin: 0 auto;
      height: 4px;
      border-radius: 2px 2px 0 0;
    }
    &.active {
      color: #151827;
    }
    &.active::after {
      background: #151827;
    }
  }
`;
