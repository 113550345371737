import React from 'react';

import { BrowserRouter, Route } from 'react-router-dom';

import { Content } from './styles';

//Pages
import Home from './pages/Home';
import Cadastro from './pages/Cadastro';
import Regulamento from './pages/Regulamento';
import Cadastrado from './pages/Cadastrado';
import Clubes from './pages/Clubes';
import ScrollToTop from './components/shared/ScrollToTop';
import Footer from './components/Footer';
import Header from './components/Header';
import { ToastContainer } from 'react-toastify';
import GlobalStyle from './styles/global';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <ScrollToTop />
        <Header/>
        <Content>
          <Route exact path='/' component={Home}></Route>
          <Route exact path='/regulamento' component={Regulamento}></Route>
          <Route exact path='/cadastro' component={Cadastro}></Route>
          <Route exact path='/cadastrado' component={Cadastrado}></Route>
          <Route exact path='/clubes' component={Clubes}></Route>
        </Content>

        <Footer />
        <GlobalStyle />
        <ToastContainer autoClose={3000} />
      </BrowserRouter>
    </div>
  );
}

export default App;
