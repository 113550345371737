import styled from 'styled-components';

export const GridLayout = styled.div`
    width: 100vw;
    height: 100vh;
    /* display: grid;
    grid-template-areas:
        "header",
        "content",
        "nav";
    grid-template-rows: 50px 1fr 50px; */
    display: flex;
    flex-direction: column;
`;

export const Header = styled.header`
    background: #fff;
    border-bottom: 1px solid #e1e1e1;
    position: fixed;
    top: 0;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding: 5px 10px;
    img {
        height: 40px;
    }
    `;

export const MainLogo = styled.img`
width: 100px;
margin-top: -60px;
margin-bottom: 5px;
`;

export const Content = styled.div`
    /* margin: 50px 0; */
    `;
